import { Injectable } from '@angular/core';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';

export enum AppRoles {
  CddAnalyst = 'CddAnalyst',
  CddAuditor = 'CddAuditor',
  CddComplianceAnalyst = 'CddComplianceAnalyst',
  CddLegalCompliance = 'CddLegalCompliance',
  CddMTCreator = 'CddM&TCreator',
  SDDBasicUser = 'SddGeneralUser',
  SDDAnalyst = 'SddAnalyst',
  SDDCompliance = 'SddCompliance'
}

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  currentUserDetails: ICvxClaimsPrincipal;

  constructor(private authService: CalAngularService) {
    this.authService.userInitiatedSignIn().subscribe();
    this.currentUserDetails = this.authService.cvxClaimsPrincipal;
  }

  async hasPermission(validRoles: string[]) {
    const userRoles = this.authService.cvxClaimsPrincipal?.roles ?? (JSON.parse(localStorage?.getItem('cachedCvxClaimsPrincipal') || ''))?._roles;
    if (userRoles) {
      for (const role of userRoles) {
        if (validRoles.includes(role)) return true;
      }
    }
    return false;
  }

}